


































import { Component, Vue } from 'vue-property-decorator'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import AboutUsService from '@/services/AboutUsService'

@Component({
  components: {
    LayoutCompact
  }
})
export default class AboutUs extends Vue {
  public aboutUs: any = {}
  public year: any = ''
  public month: any = ''
  public day: any = ''
  public full_address: string = ''

  created() {
    this.fetchAboutUs()
  }

  private fetchAboutUs() {
    AboutUsService.getAboutUs().then(
      (response: any) => {
        this.aboutUs = response.data.items
        this.full_address =
          this.aboutUs.prefecture +
          ' ' +
          this.aboutUs.city +
          ' ' +
          this.aboutUs.address1
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
        this.aboutUs =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()
      }
    )
  }
}
