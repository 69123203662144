import Const from '../constants'
import axiosApiInstance from '../interceptors'

class AboutUsService {
  getAboutUs() {
    return axiosApiInstance.get(Const.api_url + 'dashboard/company-info')
  }
}

export default new AboutUsService()
